"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.forTesting = exports.Euchre = void 0;
const card_1 = require("./card");
const util_1 = require("./util");
var Euchre;
(function (Euchre) {
    Euchre.ALL_CARDS = [0, 1, 2, 3]
        .map((suit) => [0, 12, 11, 10, 9, 8].map((x) => x + suit * 13))
        .flat(1);
    function deal() {
        const shuffled = (0, util_1.shuffleArray)([...Euchre.ALL_CARDS]);
        return {
            hands: util_1.Util.ensureQuad([0, 1, 2, 3].map((x) => shuffled.slice(0 + x * 5, 5 + x * 5))),
            faceup: shuffled[20],
        };
    }
    Euchre.deal = deal;
    function canCardBePlayed(trumpSuit, hand, cardIdx, leadCard) {
        if (cardIdx < 0 || cardIdx >= hand.length) {
            throw new Error("input out of bounds");
        }
        const allowedIndexes = allowedCards(trumpSuit, hand, leadCard);
        return allowedIndexes[cardIdx];
    }
    Euchre.canCardBePlayed = canCardBePlayed;
    function allowedCards(trumpSuit, hand, leadCard) {
        // Filter for cards that match lead suit
        const leadSuit = leadCard == null ? undefined : cardSuitInHand(trumpSuit, leadCard);
        const allowedIndexes = hand.reduce((acc, cur, idx) => {
            if (leadSuit === cardSuitInHand(trumpSuit, cur)) {
                acc.push(idx);
            }
            return acc;
        }, []);
        // If matches found, return array with true in indexes that matched
        if (allowedIndexes.length !== 0) {
            return hand.map((_, idx) => allowedIndexes.includes(idx));
        }
        // otherwise all cards are allowed
        return Array(hand.length).fill(true);
    }
    Euchre.allowedCards = allowedCards;
    /**
     * Only for use in deciding what card won the trick
     * @param trump
     * @param cards
     * @returns
     */
    function winningCardIdx(trump, cards) {
        if (cards.length === 0) {
            throw new Error("array empty");
        }
        const sorted = rankCards(trump, cards, cards[0]);
        return cards.findIndex((x) => x === sorted[0]);
    }
    Euchre.winningCardIdx = winningCardIdx;
    /**
     * Sort cards according to power (more power = earlier) with the given trump and optional lead suit.
     *
     * @param trump
     * @param cards
     * @param leadCard
     * @returns
     */
    function rankCards(trump, cards, leadCard) {
        return [...cards].sort(getRanker(trump, leadCard));
    }
    Euchre.rankCards = rankCards;
    function cardSuitInHand(trump, deckIndex) {
        if (LEFT_BOWER_MAP[trump] === deckIndex)
            return trump;
        return card_1.Card.suit(deckIndex);
    }
    Euchre.cardSuitInHand = cardSuitInHand;
    function isCardTrump(trump, deckIndex) {
        return (card_1.Card.suit(deckIndex) === trump || LEFT_BOWER_MAP[trump] === deckIndex);
    }
    Euchre.isCardTrump = isCardTrump;
})(Euchre || (exports.Euchre = Euchre = {}));
const LEFT_BOWER_MAP = {
    SPADES: card_1.JACK_MAP["CLUBS"],
    HEARTS: card_1.JACK_MAP["DIAMONDS"],
    DIAMONDS: card_1.JACK_MAP["HEARTS"],
    CLUBS: card_1.JACK_MAP["SPADES"],
};
function isCardSuit(suit, card) {
    const cardInfo = card_1.Card.fromDeckIndex(card);
    return ((cardInfo[1] === suit && LEFT_BOWER_MAP[suit] !== card) ||
        LEFT_BOWER_MAP[suit] === card);
}
function getRanker(trump, leadCard) {
    return (a, b) => {
        const aTrump = Euchre.cardSuitInHand(trump, a) === trump;
        const bTrump = Euchre.cardSuitInHand(trump, b) === trump;
        if (aTrump && !bTrump)
            return -1;
        if (bTrump && !aTrump)
            return 1;
        if (leadCard !== undefined) {
            const leadSuit = Euchre.cardSuitInHand(trump, leadCard);
            const aLead = Euchre.cardSuitInHand(leadSuit, a) === leadSuit;
            const bLead = Euchre.cardSuitInHand(leadSuit, b) === leadSuit;
            if (aLead && !bLead)
                return -1;
            if (bLead && !aLead)
                return 1;
        }
        const aInfo = card_1.Card.fromDeckIndex(a);
        const bInfo = card_1.Card.fromDeckIndex(b);
        if (!aTrump && !bTrump) {
            // non trump: compare values directly, left bower already excluded
            const order = ["ACE", "KING", "QUEEN", "JACK", "10", "9"];
            // a - b => if a is earlier (ie result is negative), order is [a,b]
            return (order.findIndex((x) => x === aInfo[0]) -
                order.findIndex((x) => x === bInfo[0]));
        }
        // trump: compare values with jack and left bower at top
        const order = [
            "JACK",
            "LEFT_BOWER",
            "ACE",
            "KING",
            "QUEEN",
            "10",
            "9",
        ];
        // a - b => if a is earlier (ie result is negative), order is [a,b]
        return (order.findIndex((x) => x === getValueOrLeftBower(trump, a)) -
            order.findIndex((x) => x === getValueOrLeftBower(trump, b)));
    };
}
function getValueOrLeftBower(trump, cardIdx) {
    if (cardIdx === LEFT_BOWER_MAP[trump]) {
        return "LEFT_BOWER";
    }
    return card_1.Card.value(cardIdx);
}
exports.forTesting = {
    getRanker,
};
