import { Protocol } from "euchre-lib";
import { FunctionComponent } from "react";
import Card from "../Card";

interface Props {
  userIndex: number;
  gamestate: Protocol.Events.Game.Gamestate;
  decide(play: boolean): void;
}

const FaceupDecision: FunctionComponent<Props> = ({
  userIndex,
  gamestate,
  decide,
}) => {
  if (gamestate.state !== Protocol.Events.Game.GamestateState.FACE_UP) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "1rem",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", columnGap: "1rem" }}>
        <Card deckIndex={gamestate.faceupCard} onClick={() => decide(true)} />
      </div>
      {gamestate.decisionPlayerIdx === userIndex && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <button disabled>Go it alone</button>
          <button
            onClick={() => {
              decide(false);
            }}
          >
            Pass
          </button>
        </div>
      )}
    </div>
  );
};

export default FaceupDecision;
