import "./Connect.css";
import { FunctionComponent } from "react";

interface Props {
  connect(): void;
}

const Connect: FunctionComponent<Props> = ({ connect }) => {
  return (
    <div className="Connect">
      <button className="Connect-Button" onClick={connect}>
        Connect
      </button>
    </div>
  );
};

export default Connect;
