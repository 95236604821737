"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Protocol = void 0;
var Protocol;
(function (Protocol) {
    let Commands;
    (function (Commands) {
        let CommandType;
        (function (CommandType) {
            CommandType["LOGIN"] = "login";
            CommandType["CREATE_LOGIN"] = "createlogin";
            CommandType["NEW_GAME"] = "newgame";
            CommandType["JOIN"] = "joingame";
            CommandType["PLAY"] = "play";
            CommandType["ADD_BOT"] = "addbot";
        })(CommandType = Commands.CommandType || (Commands.CommandType = {}));
        let Play;
        (function (Play) {
            let Type;
            (function (Type) {
                Type["FACEUP_DECISION"] = "FACEUP_DECISION";
                Type["FACEDOWN_DECISION"] = "FACEDOWN_DECISION";
                Type["DEALER_DISCARD"] = "DEALER_DISCARD";
                Type["PLAY_CARD"] = "PLAY_CARD";
            })(Type = Play.Type || (Play.Type = {}));
        })(Play = Commands.Play || (Commands.Play = {}));
    })(Commands = Protocol.Commands || (Protocol.Commands = {}));
    let Events;
    (function (Events) {
        let Type;
        (function (Type) {
            Type["LOGIN"] = "LOGIN";
            Type["GAME"] = "GAME";
        })(Type = Events.Type || (Events.Type = {}));
        let Login;
        (function (Login) {
            let Result;
            (function (Result) {
                Result["SUCCESS"] = "SUCCESS";
                Result["FAILURE"] = "FAILURE";
            })(Result = Login.Result || (Login.Result = {}));
        })(Login = Events.Login || (Events.Login = {}));
        let Game;
        (function (Game) {
            let GamestateState;
            (function (GamestateState) {
                GamestateState["WAITING_FOR_PLAYERS"] = "WAITING_FOR_PLAYERS";
                GamestateState["FACE_UP"] = "FACE_UP";
                GamestateState["FACE_DOWN"] = "FACE_DOWN";
                GamestateState["DEALER_DISCARD"] = "DEALER_DISCARD";
                GamestateState["PLAYING"] = "PLAYING";
                GamestateState["END_OF_TRICK"] = "END_OF_TRICK";
                GamestateState["END_OF_HAND"] = "END_OF_HAND";
                GamestateState["FINISHED"] = "FINISHED";
            })(GamestateState = Game.GamestateState || (Game.GamestateState = {}));
        })(Game = Events.Game || (Events.Game = {}));
    })(Events = Protocol.Events || (Protocol.Events = {}));
})(Protocol || (exports.Protocol = Protocol = {}));
