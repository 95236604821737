import { FunctionComponent } from "react";
import "./Header.css";
import { ReadyState } from "react-use-websocket";
import { Protocol } from "euchre-lib";
import "./Header.css";
import Score from "../table/score/Score";
import Tricks from "../table/score/Tricks";

interface Props {
  readyState: ReadyState;
  user?: { userId: string };
  gamestate?: Protocol.Events.Game.Gamestate;
}

const Header: FunctionComponent<Props> = ({ readyState, user, gamestate }) => {
  const userIndex = gamestate?.players.findIndex(
    (x) => x?.userId === user?.userId
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Connected",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Waiting",
  }[readyState];

  return (
    <div className="App-Header">
      <div className="Header-Alt">
        {userIndex != null && gamestate && (
          <Score gamestate={gamestate} index={userIndex} />
        )}
        {userIndex != null && gamestate && (
          <Tricks gamestate={gamestate} index={userIndex} />
        )}
      </div>
      <div className="App-Header-Name">EuchreMP</div>
      <div className="App-Header-Content">
        <div className="App-Header-Server">
          Server <strong>{connectionStatus}</strong>
        </div>

        {user && (
          <div className="App-Header-User">
            User <strong>{user.userId}</strong>
          </div>
        )}
        {gamestate && (
          <div className="App-Header-Game">
            Playing <strong>{gamestate.id}</strong>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
