import { Protocol } from "euchre-lib";
import { FunctionComponent } from "react";

interface Props {
  gamestate: Protocol.Events.Game.Gamestate;
  index: number;
}

const Score: FunctionComponent<Props> = ({ gamestate, index }) => {
  if (
    gamestate.state === Protocol.Events.Game.GamestateState.WAITING_FOR_PLAYERS
  ) {
    return null;
  }

  let yourScore, theirScore;
  if (index % 2 === 0) {
    yourScore = gamestate.team02score;
    theirScore = gamestate.team13score;
  } else {
    yourScore = gamestate.team13score;
    theirScore = gamestate.team02score;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div>SCORE</div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>YOU</div>
          <div>{yourScore}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>THEM</div>
          <div>{theirScore}</div>
        </div>
      </div>
    </div>
  );
};
export default Score;
