import { FunctionComponent } from "react";
import { Protocol } from "euchre-lib";
import Card from "./Card";

interface Props {
  gamestate: Protocol.Events.Game.Gamestate;
  index: number;
}

const PlayedCard: FunctionComponent<Props> = ({ gamestate, index }) => {
  if (gamestate.state !== Protocol.Events.Game.GamestateState.PLAYING) {
    return <Card spacer />;
  }

  const myPlayedCardIndex = (4 - gamestate.leadPlayerIndex + index) % 4;
  const myCard = gamestate.playedCards[myPlayedCardIndex];

  if (myCard == null) return <Card spacer />;
  return <Card deckIndex={myCard} />;
};

export default PlayedCard;
