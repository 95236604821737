"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shuffleArray = exports.Util = void 0;
var Util;
(function (Util) {
    function ensureQuad(arr) {
        if (arr.length !== 4) {
            throw new Error("array wrong length");
        }
        return arr;
    }
    Util.ensureQuad = ensureQuad;
})(Util || (exports.Util = Util = {}));
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
exports.shuffleArray = shuffleArray;
