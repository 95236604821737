import "./Card.css";
import { FunctionComponent } from "react";
import { Card as CardLib } from "euchre-lib";

interface Props {
  deckIndex?: number;
  suit?: CardLib.Suit;
  selected?: boolean;
  disabled?: boolean;
  trump?: boolean;
  spacer?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  otherPlayerCard?: { cardIdx: number; cardCound: number };
}

const Card: FunctionComponent<Props> = ({
  deckIndex,
  suit,
  onClick,
  selected,
  disabled,
  spacer,
  trump,
  otherPlayerCard,
}) => {
  let className = "Card";
  if (deckIndex != null) {
    className += CardLib.isSuitRed(CardLib.suit(deckIndex)) ? " Red" : " Black";
  } else if (suit != null) {
    className += CardLib.isSuitRed(suit) ? " Red" : " Black";
  }
  if (selected) {
    className += " Selected";
  }
  if (trump) {
    className += " Trump";
  }
  if (spacer) {
    className += " Spacer";
  }
  if (otherPlayerCard !== undefined) {
    className += ` Index${otherPlayerCard.cardIdx}`;
  }

  let finalOnclick = onClick;
  if (disabled) {
    className += " Disabled";
    finalOnclick = undefined;
  }

  if (finalOnclick !== undefined && !disabled) {
    className += " Clickable";
  }

  if (otherPlayerCard !== undefined) {
    return <div className={className}>
      <div className="Card-Count">{otherPlayerCard.cardCound}</div>
    </div>;
  }

  if (deckIndex === undefined) {
    return (
      <div className={className} onClick={finalOnclick}>
        {suit != null && <div>{stringForSuit[suit]}</div>}
      </div>
    );
  }

  const cardInfo = CardLib.fromDeckIndex(deckIndex);
  return (
    <div className={className} onClick={finalOnclick}>
      <div>{stringForValue[cardInfo[0]]}</div>
      <div>{stringForSuit[cardInfo[1]]}</div>
    </div>
  );
};

export default Card;

export const stringForSuit: { [TKey in CardLib.Suit]: string } = {
  CLUBS: String.fromCodePoint(0x2663),
  SPADES: String.fromCodePoint(0x2660),
  HEARTS: String.fromCodePoint(0x2665),
  DIAMONDS: String.fromCodePoint(0x2666),
};

const stringForValue: { [TKey in CardLib.Value]: string } = {
  ACE: "A",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
  JACK: "J",
  QUEEN: "Q",
  KING: "K",
};

// TODO: 35 is showing up as 1 of diamonds
