import { FormEvent } from "react";
import TextInput from "../../primitive/input/TextInput";
import Button from "../../primitive/button/Button";

const LOGIN_ID = "login-button";
const CREATE_ID = "create-button";

interface Props {
  login: (username: string, password: string) => void;
  create: (username: string, password: string) => void;
}

export default function Login(props: Props) {
  function handleLoginFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const submitter = (event.nativeEvent as SubmitEvent).submitter?.id;
    const tgt = event.currentTarget;
    const username = tgt.username.value;
    const password = tgt.password.value;
    if (submitter === LOGIN_ID) {
      props.login(username, password);
    } else if (submitter === CREATE_ID) {
      props.create(username, password);
    } else {
      console.error("Unexpected login form submitter", submitter);
    }
  }
  return (
    <div className="flex flex-col items-center">
      <form
        className="flex flex-col gap-y-1 bg-cyan-200 p-2 rounded-md"
        onSubmit={handleLoginFormSubmit}
      >
        <TextInput id="username" placeholder="username" />
        <TextInput id="password" placeholder="password" type="password" />
        <Button id={LOGIN_ID} type="submit">
          Login
        </Button>
        <div className="border-t-2 border-cyan-300" />
        <Button id={CREATE_ID} type="submit">
          Create
        </Button>
      </form>
    </div>
  );
}
