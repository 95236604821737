import { Protocol } from "euchre-lib";
import { FunctionComponent } from "react";

interface Props {
  gamestate: Protocol.Events.Game.Gamestate;
  index: number;
}

const Tricks: FunctionComponent<Props> = ({ gamestate, index }) => {
  if (
    gamestate.state !== Protocol.Events.Game.GamestateState.PLAYING &&
    gamestate.state !== Protocol.Events.Game.GamestateState.END_OF_TRICK
  ) {
    return null;
  }

  let yourTricks, theirTricks;
  if (index % 2 === 0) {
    yourTricks = gamestate.team02Tricks;
    theirTricks = gamestate.team13Tricks;
  } else {
    yourTricks = gamestate.team13Tricks;
    theirTricks = gamestate.team02Tricks;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div>TRICKS</div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>YOU</div>
          <div>{yourTricks}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>THEM</div>
          <div>{theirTricks}</div>
        </div>
      </div>
    </div>
  );
};
export default Tricks;
