"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bots = void 0;
const card_1 = require("./card");
const euchre_1 = require("./euchre");
const util_1 = require("./util");
var Bots;
(function (Bots) {
    Bots.MINIMAL_INTERACTION_BOT = {
        playDecider(data) {
            // if following, play the first allowed card
            const allowedPlays = euchre_1.Euchre.allowedCards(data.trumpSuit, data.hand, data.playedCards[0]);
            return allowedPlays.findIndex((x) => x === true);
        },
        faceupDecider(_data) {
            // never order up
            return false;
        },
        facedownDecider(_data) {
            // never order up
            return undefined;
        },
        dealerDiscardDecider(_data) {
            // always discard first card
            return 0;
        },
    };
    Bots.RANDOM_BOT = {
        playDecider: function (data) {
            const allowedPlays = euchre_1.Euchre.allowedCards(data.trumpSuit, data.hand, data.playedCards[0]);
            const trueIndexes = allowedPlays.reduce((acc, cur, idx) => {
                if (cur) {
                    acc.push(idx);
                }
                return acc;
            }, []);
            return (0, util_1.shuffleArray)(trueIndexes)[0];
        },
        faceupDecider: function (_data) {
            return (0, util_1.shuffleArray)([true, false])[0];
        },
        facedownDecider: function (data) {
            return (0, util_1.shuffleArray)([...card_1.Card.ALL_SUITS, undefined].filter((x) => x !== data.facedownSuit))[0];
        },
        dealerDiscardDecider: function (_data) {
            return (0, util_1.shuffleArray)([0, 1, 2, 3, 4, 5])[0];
        },
    };
    Bots.NAIVE_BOT = {
        // Always play the highest valued card allowed
        playDecider: function (data) {
            const allowedCards = euchre_1.Euchre.allowedCards(data.trumpSuit, data.hand, data.playedCards[0]);
            const allowedCardValues = data.hand.filter((_, idx) => allowedCards[idx]);
            const winner = euchre_1.Euchre.rankCards(data.trumpSuit, allowedCardValues)[0];
            return data.hand.findIndex((x) => x === winner);
        },
        // Pick up if # trump >= 3. (if dealer, pick up if # trump >= 2 because you will add one trump)
        faceupDecider: function (data) {
            const threshold = data.dealerIdx === data.playerIdx ? 2 : 3;
            const trump = card_1.Card.suit(data.faceupCard);
            return (data.hand.filter((x) => euchre_1.Euchre.cardSuitInHand(trump, x) === trump)
                .length >= threshold);
        },
        // Order up a suit that will give you >= 3 trump cards
        facedownDecider: function (data) {
            const threshold = 3;
            const counts = data.hand.reduce((acc, cur) => {
                for (const suit of card_1.Card.ALL_SUITS) {
                    if (euchre_1.Euchre.cardSuitInHand(suit, cur) === suit) {
                        acc[suit]++;
                    }
                }
                return acc;
            }, { SPADES: 0, HEARTS: 0, DIAMONDS: 0, CLUBS: 0 });
            for (const [suit, count] of Object.entries(counts)) {
                if (suit !== data.facedownSuit && count >= threshold) {
                    return suit;
                }
            }
            return undefined;
        },
        // Discard the lowest card
        dealerDiscardDecider: function (data) {
            const cardsRanked = euchre_1.Euchre.rankCards(data.trumpSuit, data.hand);
            return data.hand.findIndex((x) => x === cardsRanked[cardsRanked.length - 1]);
        },
    };
})(Bots || (exports.Bots = Bots = {}));
