import { FunctionComponent } from "react";
import Card from "./Card";
import { Euchre, Protocol } from "euchre-lib";
import "./Player.css";

interface Props {
  gamestate: Protocol.Events.Game.Gamestate;
  index: number;
  rhs?: boolean;
  decideDealerDiscard(index: number): void;
  decidePlay(index: number): void;
}

const Player: FunctionComponent<Props> = ({
  gamestate,
  index,
  rhs,
  decideDealerDiscard,
  decidePlay,
}) => {
  const player = gamestate.players[index];
  if (player == null) {
    return <div>Waiting for player to join...</div>;
  }

  const isDealer =
    gamestate.state !==
      Protocol.Events.Game.GamestateState.WAITING_FOR_PLAYERS &&
    gamestate.state !== Protocol.Events.Game.GamestateState.FINISHED &&
    gamestate.state !== Protocol.Events.Game.GamestateState.END_OF_HAND &&
    gamestate.dealerPlayerIdx === index;

  const isMyPlay =
    gamestate.state === Protocol.Events.Game.GamestateState.PLAYING &&
    gamestate.playedCards.length < 4 &&
    (gamestate.leadPlayerIndex + gamestate.playedCards.length) % 4 === index;

  const isMyFaceupDecision =
    gamestate.state === Protocol.Events.Game.GamestateState.FACE_UP &&
    gamestate.decisionPlayerIdx === index;

  const isMyFacedownDecision =
    gamestate.state === Protocol.Events.Game.GamestateState.FACE_DOWN &&
    gamestate.decisionPlayerIdx === index;

  const isTrickWinner =
    gamestate.state === Protocol.Events.Game.GamestateState.END_OF_TRICK &&
    gamestate.leadPlayerIndex === index;

  const isHandWinner =
    gamestate.state === Protocol.Events.Game.GamestateState.END_OF_HAND &&
    index % 2 === gamestate.winningTeam;

  function getOnclickHandler(cardIdx: number) {
    let onClick: React.MouseEventHandler<HTMLDivElement> | undefined =
      undefined;
    if (
      gamestate.state === Protocol.Events.Game.GamestateState.DEALER_DISCARD &&
      gamestate.dealerPlayerIdx === index
    ) {
      onClick = () => {
        decideDealerDiscard(cardIdx);
      };
    } else if (isMyPlay) {
      onClick = () => {
        decidePlay(cardIdx);
      };
    }
    return onClick;
  }

  let cards = Array(5).fill(<Card spacer />);

  if (
    gamestate.state === Protocol.Events.Game.GamestateState.FACE_UP ||
    gamestate.state === Protocol.Events.Game.GamestateState.FACE_DOWN ||
    gamestate.state === Protocol.Events.Game.GamestateState.PLAYING ||
    gamestate.state === Protocol.Events.Game.GamestateState.END_OF_TRICK ||
    gamestate.state === Protocol.Events.Game.GamestateState.DEALER_DISCARD
  ) {
    const player2 = gamestate.players[index];

    let playerAllowedCards = Array(
      player2.playerType === "YOU" ? player2.cards.length : player2.cardCount
    ).fill(true);
    let playerTrumpCards = Array(
      player2.playerType === "YOU" ? player2.cards.length : player2.cardCount
    ).fill(false);
    if (
      gamestate.state === Protocol.Events.Game.GamestateState.PLAYING &&
      gamestate.playedCards.length !== 0 &&
      player2.playerType === "YOU"
    ) {
      playerAllowedCards = Euchre.allowedCards(
        gamestate.trump,
        player2.cards,
        gamestate.playedCards[0]
      );
      playerTrumpCards = player2.cards.map((x) =>
        Euchre.isCardTrump(gamestate.trump, x)
      );
    }

    cards =
      player2.playerType === "YOU"
        ? player2.cards.map((x, idx) => (
            <Card
              deckIndex={x}
              onClick={getOnclickHandler(idx)}
              disabled={isMyPlay && !playerAllowedCards[idx]}
              trump={playerTrumpCards[idx]}
            />
          ))
        : Array(player2.cardCount)
            .fill(null)
            .map((_, idx) => (
              <Card
                otherPlayerCard={{
                  cardIdx: idx,
                  cardCound: player2.cardCount,
                }}
              />
            ));
  }

  let className = "Player";
  if (isMyPlay || isMyFaceupDecision || isMyFacedownDecision) {
    className += " Playing";
  }

  if (isTrickWinner) {
    className += " Trick-Winner";
  }

  if (isHandWinner) {
    className += " Hand-Winner";
  }

  return (
    <div className={className}>
      <div
        style={{
          marginBottom: "0.25rem",
          display: "flex",
          flexDirection: rhs ? "row-reverse" : "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <strong>{player.isBot ? "BOT" : player.userId}</strong>
        </div>
        <div>{isDealer ? "Dealer" : ""}</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: rhs ? "row-reverse" : "row",
          columnGap: "0.25rem",
        }}
      >
        {cards}
      </div>
    </div>
  );
};

export default Player;
