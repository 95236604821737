import "./App.css";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useState } from "react";
import Table from "./components/table/Table";
import Login from "./components/login/Login";
import Connect from "./components/connect/Connect";
import Gamebrowser from "./components/gamebrowser/Gamebrowser";
import { useInterval } from "./hooks/useInterval";
import Header from "./components/header/Header";
import { Protocol } from "euchre-lib";

function App() {
  const [connect, setConnect] = useState(false);

  const { sendJsonMessage, readyState, lastJsonMessage } = useWebSocket(
    "wss://e77ub5q4t3.execute-api.us-west-2.amazonaws.com/production/",
    undefined,
    connect
  );

  const [user, setUser] = useState<{ userId: string } | undefined>(undefined);
  const [currentGameStates, setCurrentGameStates] = useState<
    Protocol.Events.Game.Gamestate[]
  >([]);
  const [currentGamestatePtr, setCurrentGamestatePtr] = useState(-1);

  useInterval(() => {
    if (
      currentGamestatePtr !== -1 &&
      currentGamestatePtr < currentGameStates.length - 1
    ) {
      setCurrentGamestatePtr((prev) => {
        console.log(`setting ptr to ${prev + 1}`);

        return prev + 1;
      });
    }
  }, 1000);

  if (
    typeof lastJsonMessage === "object" &&
    lastJsonMessage != null &&
    "type" in lastJsonMessage
  ) {
    const messageTyped = lastJsonMessage as Protocol.Events.Event;
    if (
      messageTyped.type === Protocol.Events.Type.LOGIN &&
      messageTyped.result === Protocol.Events.Login.Result.SUCCESS
    ) {
      if (user === undefined) {
        setUser({ userId: messageTyped.userId });
      }
    } else if (messageTyped.type === Protocol.Events.Type.GAME) {
      if (
        currentGameStates.length === 0 ||
        currentGameStates[currentGameStates.length - 1].version <
          messageTyped.gamestate.version
      ) {
        setCurrentGameStates((prev) => [...prev, messageTyped.gamestate]);
        if (currentGamestatePtr === -1) {
          setCurrentGamestatePtr(0);
        }
      }
    } else {
      console.log(`Invalid event type ${messageTyped.type}`);
    }
  }

  console.log(currentGameStates);

  const currentGameState: Protocol.Events.Game.Gamestate | null =
    currentGameStates[currentGamestatePtr];

  return (
    <div className="App">
      <Header
        readyState={readyState}
        user={user}
        gamestate={currentGameState}
      />
      {!connect && (
        <Connect
          connect={() => {
            setConnect(true);
          }}
        />
      )}
      {readyState === ReadyState.OPEN && !user && !currentGameState && (
        <Login
          login={(username, password) => {
            const loginCommand: Protocol.Commands.Login = {
              action: Protocol.Commands.CommandType.LOGIN,
              username,
              password,
            };
            sendJsonMessage(loginCommand);
          }}
          create={(u, p) => {
            console.error("create not implemented");
          }}
        />
      )}
      {user && !currentGameState && (
        <Gamebrowser
          user={user}
          joinGame={(gameId) => {
            const joinCommand: Protocol.Commands.Join = {
              action: Protocol.Commands.CommandType.JOIN,
              gameId,
            };
            sendJsonMessage(joinCommand);
          }}
          newGame={() => {
            const newgameCommand: Protocol.Commands.Newgame = {
              action: Protocol.Commands.CommandType.NEW_GAME,
            };
            sendJsonMessage(newgameCommand);
          }}
        />
      )}
      {user && currentGameState && (
        <Table
          user={user}
          gameState={currentGameState}
          playGame={(decision) => {
            const playCommand: Protocol.Commands.Play.Command = {
              action: Protocol.Commands.CommandType.PLAY,
              gameId: currentGameState.id,
              decision,
            };
            sendJsonMessage(playCommand);
          }}
          addBot={() => {
            const addBotCommand: Protocol.Commands.AddBot = {
              action: Protocol.Commands.CommandType.ADD_BOT,
              gameId: currentGameState.id,
            };
            sendJsonMessage(addBotCommand);
          }}
        />
      )}
      <div className="App-Footer">
        <div>EuchreMP by tgnhrkn</div>
      </div>
    </div>
  );
}

export default App;
