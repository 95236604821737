import { FunctionComponent } from "react";
import Player from "./Player";
import { EuchreUser } from "../../lib/user";
import { stringForSuit } from "./Card";
import { Protocol } from "euchre-lib";
import FaceupDecision from "./faceup/FaceupDecision";
import FacedownDecision from "./facedown/FacedownDecision";
import PlayedCard from "./PlayedCard";
import "./Table.css";
import Score from "./score/Score";
import Tricks from "./score/Tricks";

interface Props {
  user: EuchreUser;
  gameState: Protocol.Events.Game.Gamestate;

  playGame: (command: Protocol.Commands.Play.Decision) => void;
  addBot: () => void;
}

const Table: FunctionComponent<Props> = ({
  user,
  gameState,
  playGame,
  addBot,
}) => {
  const userIndex = gameState.players.findIndex(
    (x) => x?.userId === user.userId
  );
  if (userIndex === -1) {
    return <div>Error</div>;
  }

  function dealerDiscard(index: number) {
    const command: Protocol.Commands.Play.DealerDiscard = {
      type: Protocol.Commands.Play.Type.DEALER_DISCARD,
      cardIdx: index,
    };
    playGame(command);
  }

  function playCard(index: number) {
    const command: Protocol.Commands.Play.PlayCard = {
      type: Protocol.Commands.Play.Type.PLAY_CARD,
      cardIdx: index,
    };
    playGame(command);
  }

  return (
    <div className="Table">
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Player
            gamestate={gameState}
            index={(userIndex + 2) % 4}
            decideDealerDiscard={dealerDiscard}
            decidePlay={playCard}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              flexBasis: 0,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Player
              gamestate={gameState}
              index={(userIndex + 1) % 4}
              decideDealerDiscard={dealerDiscard}
              decidePlay={playCard}
            />
          </div>

          <div>
            <FaceupDecision
              gamestate={gameState}
              userIndex={userIndex}
              decide={(play: boolean) => {
                const decision: Protocol.Commands.Play.Faceup = {
                  type: Protocol.Commands.Play.Type.FACEUP_DECISION,
                  play,
                };
                playGame(decision);
              }}
            />

            <FacedownDecision
              gamestate={gameState}
              userIndex={userIndex}
              decide={(play) => {
                const decision: Protocol.Commands.Play.Facedown = {
                  type: Protocol.Commands.Play.Type.FACEDOWN_DECISION,
                  play,
                };
                playGame(decision);
              }}
            />
            {gameState.state ===
              Protocol.Events.Game.GamestateState.WAITING_FOR_PLAYERS && (
              <div>
                <button onClick={addBot}>Add Bot Player</button>
              </div>
            )}
            {(gameState.state === Protocol.Events.Game.GamestateState.PLAYING ||
              gameState.state ===
                Protocol.Events.Game.GamestateState.END_OF_TRICK ||
              gameState.state ===
                Protocol.Events.Game.GamestateState.DEALER_DISCARD) && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <PlayedCard
                    gamestate={gameState}
                    index={(userIndex + 2) % 4}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <PlayedCard
                    gamestate={gameState}
                    index={(userIndex + 1) % 4}
                  />
                  <div
                    style={{
                      fontSize: "6rem",
                      opacity: "50%",
                      color:
                        gameState.trump === "CLUBS" ||
                        gameState.trump === "SPADES"
                          ? "black"
                          : "red",
                    }}
                  >
                    {stringForSuit[gameState.trump]}
                  </div>
                  <PlayedCard
                    gamestate={gameState}
                    index={(userIndex + 3) % 4}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <PlayedCard
                    gamestate={gameState}
                    index={(userIndex + 0) % 4}
                  />
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              flexGrow: 1,
              flexBasis: 0,
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Player
              gamestate={gameState}
              index={(userIndex + 3) % 4}
              decideDealerDiscard={dealerDiscard}
              decidePlay={playCard}
              rhs
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-end",
            }}
          >
            <div className="Score-Bottom">
              <Score gamestate={gameState} index={userIndex} />
            </div>
          </div>
          <div>
            <Player
              gamestate={gameState}
              index={userIndex % 4}
              decideDealerDiscard={dealerDiscard}
              decidePlay={playCard}
            />
          </div>

          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "flex-start",
              alignItems: "flex-end",
            }}
          >
            <div className="Tricks-Bottom">
              <Tricks gamestate={gameState} index={userIndex} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
