"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JACK_MAP = exports.Card = void 0;
var Card;
(function (Card) {
    Card.ALL_CARD_VALUES = [
        "ACE",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "JACK",
        "QUEEN",
        "KING",
    ];
    Card.ALL_SUITS = ["SPADES", "HEARTS", "DIAMONDS", "CLUBS"];
    function fromDeckIndex(deckIndex) {
        return [value(deckIndex), suit(deckIndex)];
    }
    Card.fromDeckIndex = fromDeckIndex;
    function suit(deckIndex) {
        const suitIdx = Math.trunc(deckIndex / Card.ALL_CARD_VALUES.length);
        return Card.ALL_SUITS[suitIdx];
    }
    Card.suit = suit;
    function value(deckIndex) {
        const cardValueIdx = deckIndex % Card.ALL_CARD_VALUES.length;
        return Card.ALL_CARD_VALUES[cardValueIdx];
    }
    Card.value = value;
    function isSuitRed(suit) {
        return suit === "DIAMONDS" || suit === "HEARTS";
    }
    Card.isSuitRed = isSuitRed;
})(Card || (exports.Card = Card = {}));
exports.JACK_MAP = {
    SPADES: 10,
    HEARTS: 23,
    DIAMONDS: 36,
    CLUBS: 49,
};
// function cardIndexes(deckIndex: number): [number, number] {
//   const suitIdx = Math.trunc(deckIndex / Card.ALL_CARD_VALUES.length);
//   const cardValueIdx = deckIndex % Card.ALL_CARD_VALUES.length;
//   return [cardValueIdx, suitIdx];
// }
// export function getCardStringFromDeckIndex(deckIndex: number): string {
//   const [cardIdx, suitIdx] = cardIndexes(deckIndex);
//   const codepoint =
//     0x1f0a1 + (cardIdx > 10 ? cardIdx + 1 : cardIdx) + 0x10 * suitIdx;
//   console.log(`${deckIndex} => [${cardIdx}, ${suitIdx}] => ${codepoint}`);
//   return String.fromCodePoint(codepoint);
// }
