import { Card as CardType, Protocol } from "euchre-lib";
import { FunctionComponent } from "react";
import Card from "../Card";

interface Props {
  userIndex: number;
  gamestate: Protocol.Events.Game.Gamestate;
  decide(play: CardType.Suit | undefined): void;
}

const FacedownDecision: FunctionComponent<Props> = ({
  decide,
  gamestate,
  userIndex,
}) => {
  if (
    gamestate.state !== Protocol.Events.Game.GamestateState.FACE_DOWN ||
    gamestate.decisionPlayerIdx !== userIndex
  ) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "1rem",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", columnGap: "1rem" }}>
        {[...CardType.ALL_SUITS]
          .filter((x) => x !== gamestate.facedownSuit)
          .map((x) => (
            <Card
              suit={x}
              onClick={() => {
                decide(x);
              }}
            />
          ))}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <button disabled>Go it alone</button>
        <button
          onClick={() => {
            decide(undefined);
          }}
        >
          Pass
        </button>
      </div>
    </div>
  );
};

export default FacedownDecision;
