import { FormEvent, FunctionComponent } from "react";
import { EuchreUser } from "../../lib/user";
import "./Gamebrowser.css";

interface Props {
  user: EuchreUser;
  newGame: () => void;
  joinGame: (gameId: string) => void;
}

const Gamebrowser: FunctionComponent<Props> = ({ user, newGame, joinGame }) => {
  function newGameHandler() {
    newGame();
  }

  function joinGameHandler(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    joinGame(event.currentTarget.game_id.value);
  }

  return (
    <div className="Gamebrowser">
      <div className="Gamebrowser-Content">
        <button onClick={newGameHandler}>Create New Game</button>
        <div className="Divider"></div>
        <form onSubmit={joinGameHandler}>
          <input id="game_id" type="text" placeholder="Game Id" />
          <button type="submit">Join Game</button>
        </form>
      </div>
    </div>
  );
};

export default Gamebrowser;
